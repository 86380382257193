import { render, staticRenderFns } from "./SpecialDetail.vue?vue&type=template&id=18fb048a&scoped=true&"
import script from "./SpecialDetail.vue?vue&type=script&lang=js&"
export * from "./SpecialDetail.vue?vue&type=script&lang=js&"
import style0 from "./SpecialDetail.vue?vue&type=style&index=0&id=18fb048a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fb048a",
  null
  
)

export default component.exports