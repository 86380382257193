<template>
	<div class="detail" v-if="info">
		<div class="content-title">{{ info.title }}</div>
		<div class="detail-wrap">
			<div class="detail-content">
				<div class="content-left">{{ '指导教师' | language }}：</div>
				<div class="content-right">{{ info.tutor }}</div>
			</div>
			<div class="detail-content">
				<div class="content-left">{{ '合作企业' | language }}：</div>
				<div class="content-right">{{ info.coopEnterprise }}</div>
			</div>
			<div class="detail-content">
				<div class="content-left">{{ '课时' | language }}：</div>
				<div class="content-right">{{ info.duration }}</div>
			</div>
			<div class="detail-content">
				<div class="content-left">{{ '人数' | language }}：</div>
				<div class="content-right">{{ info.maxStudent }}/{{ '期' | language }}</div>
			</div>
		</div>
		<div class="other-title">{{ '教学内容' | language }}</div>
		<div class="other-content">{{ info.content }}</div>
		<div class="other-title">{{ '竞赛' | language }}、{{ '课题' | language }}</div>
		<div class="other-content">
			<div v-for="(item, index) in info.subjects" :key="index">{{ item }}</div>
		</div>
		<div class="other-title">{{ '产出方向' | language }}</div>
		<div class="other-content direction">
			<div v-for="(item, index) in info.outputs" :key="index">
				{{ item }} <span v-if="index - 1">、</span>
			</div>
		</div>
	</div>
</template>

<script>
import { downloadMinxin } from '../../mixin/download.js';
export default {
	mixins: [downloadMinxin],
	components: {},
	data() {
		return {
			id: '',
			info: null,
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.fetchData();
	},
	methods: {
		showDialog() {
			this.$refs.add.edit(this.id);
		},
		async fetchData() {
			let res = await this.$http.get(`/information/course/${this.id}`);

			this.info = res;
		},
	},
};
</script>

<style lang="less" scoped>
.detail {
	text-align: left;
	min-height: 1000px;
	.content-title {
		font-size: 30px;
		color: rgba(0, 0, 0, 0.9);
		line-height: 32px;
	}
	.detail-wrap {
		margin-top: 20px;
		.detail-content {
			display: flex;
			font-size: 16px;
			line-height: 29px;
			.content-left {
				width: 80px;
				color: rgba(0, 0, 0, 0.6);
			}
			.cotent-right {
				color: rgba(0, 0, 0, 0.9);
			}
		}
	}
	.other-title {
		margin-top: 40px;
		font-size: 22px;
		color: rgba(0, 0, 0, 0.9);
		letter-spacing: 0;
		line-height: 32px;
	}
	.other-content {
		margin-top: 10px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 29px;
	}
	.direction {
		display: flex;
	}
}
</style>
